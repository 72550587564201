<template>
  <div>
    <b-container class="base-container-x contact-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <!-- <div class="banner-inner contact">
        <div v-if="loading == false" class="slideshow_caption">
          <div class="slideshow_inner_caption" style="position: absolute;">
            <div class="slideshow_align_caption">
              <h1 class="section-header-title">{{ $t('Contact') }}</h1>
              <h3 class="article-content">{{ $t('If you have any questions or need assistance, feel free to contact us.!') }}</h3>
            </div>
          </div>
        </div>
      </div> -->
      <div v-if="loading == true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading == false" class="col-12 mb-5 pt-4">
        <div class="row" v-if="videos.length > 0">
            <div  v-for="video in videos" :key="video.id" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 pl-md-2 pr-md-2">
              <router-link :to="{ name: 'video', params: { id: video.id }}">
              <VideoItem :item="video"/>
              </router-link>
            </div>
        </div>
        <div v-else class="row text-center justify-content-center">
          <div class="search-notfound-container">
            <div class="search-notfound-image-box">
              <b-img  blank-color="#fff" style="height:100%;" :src="require('../../../assets/images/icon/yoga_search_not_found.png')"></b-img>
            </div>
            <h5 class="d-block pt-3">{{ $t('Not found video') }}</h5>
          </div>
        </div>
        <div class="mt-2 mb-2" v-if="videos.length > 0">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="this.itemPerpage"
            :first-text="$t('First')"
            :prev-text="$t('Prev')"
            :next-text="$t('Next')"
            :last-text="$t('Last')"
            class="justify-content-center">
          </b-pagination>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import VideoItem from './VideoItem'
import Api from '../../../api/api'
export default {
  name: 'Videos',
  title: ' | Videos',
  components: {
    VideoItem
  },
  data () {
    return {
      loading: true,
      totalRows: 0,
      videos: [],
      toItem: 0,
      currentPage: 1,
      itemPerpage: 20,
      isLoading: false
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.getVideo()
  },
  watch: {
    currentPage: function () {
      this.getVideo(false)
    }
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    getVideo (isViewMore) {
      const payload = {
        is_public: 1,
        page: this.currentPage,
        limit: this.itemPerpage,
        offset: (this.currentPage - 1) * this.itemPerpage
      }
      this.isLoading = true
      Api.video(payload).then(response => {
        if (response.data.results) {
          if (isViewMore) {
            this.videos.push(response.data)
          } else {
            this.videos = response.data.results
          }
          this.toItem = (parseInt(this.videos.count) < this.itemPerpage ? this.videos.count : (this.currentPage * this.itemPerpage))
          this.totalRows = response.data.count
        }
        this.isLoading = false
        this.loading = false
      }).catch(() => {
        this.isLoading = false
        this.loading = false
      })
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
.video-item {
    cursor: pointer;
    display: flex;
    margin-bottom: 20px;
  }
  .video-item .cover {
    position: relative;
    width: 166px;
    overflow: hidden;
    background: #000;
  }
  .video-item:hover .cover img {
    opacity: .7;
    transform: scale(1.25, 1.25);

  }
  .video-item .cover img {
    width: 100%;
    transition: transform ease .25s;
  }
  .video-item .cover .duration {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 3px 6px;
    line-height: 1.2em;
    background-color: rgba(0,0,0, .65);
    color: #fff;
    font-size: 12px;
    border-radius: 2px;
  }
   .video-item .detail {
    padding-left: 10px;
    font-size: 14px;
    color: rgba(0,0,0, .45);
  }
  .video-item .detail .title {
    color: #000;
    font-size: 16px;
  }
  .video-item .detail .author{
    font-size: 13px;
    line-height: 1em;
  }
  .video-item .detail .date {
    display: inline-block;
    padding: 3px 6px;
    line-height: 1em;
    background-color: #31C2F2;
    color: #fff;
    font-size: 12px;
    border-radius: 2px;
  }
  .video-item .detail .data.hot {
    background-color: #ff6060;
  }
</style>
